import axios from '@/api/config/interceptor'

import {
    host,
    fileUpload
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

//获取数据字典信息
export const getDataDict = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetDataDict?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 所有运单获取 场景类型
export const getTypeAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/Sys/GetDatadictionarySSCInvoiceTypeAsync/${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取系统配置
export const getSystemConfig = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetSystemConfig?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取地图轨迹
export const getMapPath = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/GetEquipTrack`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const FileDowUrl = (params, type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/FileDowUrl`, params, type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//地址逆解析
export const reverseCoord = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/ReverseCoord?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取车辆信息
export const getCarInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Vehicle/GetVehicleDetailsByWay?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取车辆信息
export const carInfoByNum = (params = {}) => {
    // return new Promise((resolve, reject) => {
    //     axios.post(`${host}/api/Vehicle/CarInfoByNum`,params).then(res => {
    //         if (res.code == 0) {
    //             resolve(res.data)
    //         } else {
    //             reject()
    //         }
    //     })
    // })
}

//获取司机信息
export const getDriverDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/GetDriverDetailsByWay`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取下载文件路径
export const GetFileInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/PlatFormData/GetFileTemplateInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 根据code查询案例数据
export const GetSampleInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/PlatFormData/GetSampleInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上报司机
export const upTrafficDriver = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TrafficReport/ReportDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 上报车辆
export const upTrafficVehicle = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TrafficReport/ReportVehicle`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 上报运单
export const upTrafficWaybill = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TrafficReport/ReportWay`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 上报资金
export const upTrafficCaptial = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TrafficReport/ReportCaptial`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 上报位置
export const upTrafficAddress = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TrafficReport/ReportWayAddress`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传图片
export const uploadImg = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Image/Upload/Img`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传合同文件
export const uploadContract = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Image/Upload/Contract`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传url返回一个url
// export const upLoadImgByUrl = (params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(`${host}/api/TempTransport/upLoadImgByUrl`, params).then(res => {
//             if (res.code == 0) {
//                 resolve(res.data)
//             } else {
//                 reject(res)
//             }
//         })
//     })
// }
export const upLoadImgByUrl = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${fileUpload}/api/FileUpload/Upload`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取省市区
export const getArea = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/GetCityInfo`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取地区子节点
export const getAreaChildren = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/GetChildDistrict?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商首页开票数据
export const GetInvoiceSummaryByMonth = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Invoice/GetInvoiceSummaryByMonth`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取数据上报公司名
export const GetSysValueAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/Sys/GetSysValueAsync?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 异步下载列表
export const DownLoadAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Sys/DownLoadAsync`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 异步下载删除
export const DownLoadDel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Sys/DownLoadDel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 创建司机对公账户
export const PreOpenDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Yumin/Account/PreOpenDriver?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 转账给对应用户
export const LedgerToUser = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Yumin/Ledger/ToUser`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 多端登录编辑
export const EditMultiterminal = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/UserLogoff/EditMultiterminal`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 新增电签
export const EContractSignAdd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/EContractSign/Add`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 电签列表
export const EContractSignPage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/EContractSign/Page`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 同意或拒绝
export const EContractSignAgree = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/EContractSign/Agree`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取法大大用户状态
export const GetFadadaStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/EContractSign/GetFadadaStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发送验证码
export const SendCode = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/EContractSign/SendCode`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取模版
export const GetContractTemplate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/EContractSign/GetContractTemplate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 通知列表
export const NotifyList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/OpenNotify/NotifyList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 通知处理
export const NotifyDeal = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/OpenNotify/NotifyDeal`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 手动重发通知
export const AutoDeal = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/OpenNotify/AutoDeal`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}